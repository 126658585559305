import {
  Avatar,
  Button,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Chip,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  CalendarOptions,
  GoogleCalendar,
  ICalendar,
  OutlookCalendar,
} from "datebook";
import * as FileSaver from "file-saver";
import { convert } from "html-to-text";
import React, { useState } from "react";
import {
  FaApple,
  FaCalendar,
  FaEllipsisV,
  FaGlassCheers,
  FaGoogle,
  FaMapMarked,
  FaMicrophoneAlt,
  FaShareAlt,
  FaWalking,
  FaWindows,
} from "react-icons/fa";
import { HiAcademicCap } from "react-icons/hi";
import { RiTeamFill } from "react-icons/ri";
import { eventTypeToColor, palette } from "../config";
import { t } from "../translate";
import { IEventProps, IIconProps } from "../types";
import { GDChCard } from "./card";

import dayjs from "dayjs";
import "dayjs/locale/de";
import "dayjs/locale/en";

const now = new Date();

const alarm = {
  action: "DISPLAY",
  description: "alarm",
  summary: "event’s alarm",
  trigger: {
    days: 1,
  },
};

function GDChChip(props) {
  return <Chip sx={{ marginRight: 2, marginTop: 2 }} {...props} />;
}

export function Event({ event, share }: IEventProps) {
  const start = dayjs(event.startDate);
  const ende = dayjs(event.endDate);

  const start0 = start.set("h", 0).set("m", 0).set("s", 0).set("ms", 0);
  const now0 = dayjs(now).set("h", 0).set("m", 0).set("s", 0).set("ms", 0);
  const tageBis = start0.diff(now0, "days");

  const hasNoEnd = ende.diff(start, "minutes") < 10;
  const isLong = ende.diff(start, "hours") > 23;
  const isEnglish = window.location.href.includes("//en.gdch.de");

  const date = isEnglish
    ? hasNoEnd
      ? start.locale("en").format("dddd, DD MMM YYYY")
      : isLong
      ? start.locale("en").format("ddd, DD MMM") +
        " — " +
        ende.locale("en").format("ddd, DD MMM YYYY")
      : start.locale("en").format("ddd, DD MMM YYYY, HH:mm") +
        "—" +
        ende.locale("en").format("HH:mm")
    : hasNoEnd
    ? start.locale("de").format("dddd, DD.MM.YYYY")
    : isLong
    ? start.locale("de").format("ddd, DD.MM.") +
      " — " +
      ende.locale("de").format("ddd, DD.MM.YYYY")
    : start.locale("de").format("ddd, DD.MM.YYYY, HH:mm") +
      "—" +
      ende.locale("de").format("HH:mm");

  const config: CalendarOptions = {
    title: event.title,
    location: event.location || event.ctaLink,
    description: event.href,
    start: new Date(event.startDate),
    end: new Date(event.endDate),
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const description =
    convert(event.description).length > 200
      ? convert(event.description).slice(0, 200) + "…"
      : convert(event.description);

  const lecturer = event?.talkLecturer
    ? `${event?.talkLecturer}, ${event?.talkLecturerAffiliation}`
    : null;

  return (
    <GDChCard>
      {event.image && event.image.mimeType !== "application/pdf" && (
        <CardMedia component="img" image={event.image.webpUrl} loading="lazy" />
      )}
      <CardHeader
        action={
          <>
            <IconButton
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              <FaEllipsisV />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              {share && (
                <MenuItem
                  onClick={async () => {
                    try {
                      await navigator.share({
                        title: event.title,
                        text: `${event.title}\n${
                          event.subtitle
                        }\n${start.format("dddd, DD.MM.YYYY, HH:mm")}`,
                        url: event.href,
                      });
                    } catch (err) {
                      console.log(`Error: ${err}`);
                    }
                    setAnchorEl(null);
                  }}
                >
                  <ListItemIcon>
                    <FaShareAlt />
                  </ListItemIcon>
                  <span style={{ paddingTop: 4 }}>{t("Teilen")}</span>
                </MenuItem>
              )}
              {event.location && (
                <MenuItem
                  onClick={() => {
                    window.open(
                      `https://www.google.com/maps/search/${event.location}`,
                      "_blank"
                    );
                    setAnchorEl(null);
                  }}
                >
                  <ListItemIcon>
                    <FaMapMarked />
                  </ListItemIcon>
                  <span style={{ paddingTop: 4 }}>{t("Karte anzeigen")}</span>
                </MenuItem>
              )}
              <MenuItem
                onClick={() => {
                  const icalendar = new ICalendar(config);
                  const ical = icalendar.addAlarm(alarm).render();
                  const blob = new Blob([ical], {
                    type: "text/calendar",
                  });
                  FileSaver.saveAs(blob, `${event.slug}.ics`);
                  setAnchorEl(null);
                }}
              >
                <ListItemIcon>
                  <FaApple />
                </ListItemIcon>
                <span style={{ paddingTop: 4 }}>{t("ICS-Datei / Apple")}</span>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  window.open(new GoogleCalendar(config).render(), "_blank");
                  setAnchorEl(null);
                }}
              >
                <ListItemIcon>
                  <FaGoogle />
                </ListItemIcon>
                <span style={{ paddingTop: 4 }}>Google {t("Kalender")}</span>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  window.open(new OutlookCalendar(config).render(), "_blank");
                  setAnchorEl(null);
                }}
              >
                <ListItemIcon>
                  <FaWindows />
                </ListItemIcon>
                <span style={{ paddingTop: 4 }}>Outlook {t("Kalender")}</span>
              </MenuItem>
            </Menu>
          </>
        }
        avatar={
          <Avatar sx={{ bgcolor: palette[eventTypeToColor(event.type)].main }}>
            <Icon name={event.type} />
          </Avatar>
        }
        title={event.organization}
        subheader={date}
      />
      <CardContent>
        <Typography variant="h6" component="div" sx={{ lineHeight: "1.2em" }}>
          {event.title}
        </Typography>
        <Typography sx={{ lineHeight: "1.2em", mt: 1 }}>
          {event.subtitle || lecturer}
        </Typography>
        <Typography sx={{ lineHeight: "1.2em", mt: 2, fontSize: "1.5em" }}>
          {description}
        </Typography>
        {tageBis === 0 && <GDChChip label={t("heute")} color="error" />}
        {tageBis === 1 && <GDChChip label={t("morgen")} color="warning" />}
        {tageBis > 1 && tageBis < 10 && (
          <GDChChip label={`${tageBis} ${t("Tage")}`} />
        )}
        {event.tags.map((txt: string) => (
          <GDChChip label={t(txt.replace("offline", "Präsenz"))} key={txt} />
        ))}
      </CardContent>
      <CardActions>
        <Tooltip title={t("In App öffnen")}>
          <a
            href={event.href || `https://gdch.app/event/${event.slug}`}
            target="_blank"
          >
            <Button
              variant="contained"
              sx={{
                boxShadow: "none",
                margin: 1,
                paddingTop: "10px",
              }}
            >
              {t("Öffnen")}
            </Button>
          </a>
        </Tooltip>
        {event.ctaLink && (
          <Tooltip title="Externen Link öffnen">
            <a href={event.ctaLink} target="_blank">
              <Button
                variant="outlined"
                sx={{
                  boxShadow: "none",
                  margin: 1,
                  paddingTop: "10px",
                }}
              >
                <div
                  style={{
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    maxWidth: 150,
                  }}
                >
                  {event.ctaTitle || "Mehr"}
                </div>
              </Button>
            </a>
          </Tooltip>
        )}
      </CardActions>
    </GDChCard>
  );
}

function Icon({ name }: IIconProps) {
  if (name === "talk") {
    return <FaMicrophoneAlt />;
  } else if (name === "symposia") {
    return <RiTeamFill />;
  } else if (name === "recurring_meetup") {
    return <FaGlassCheers />;
  } else if (name === "academy") {
    return <HiAcademicCap />;
  } else if (name === "excursion") {
    return <FaWalking />;
  } else {
    return <FaCalendar />;
  }
}

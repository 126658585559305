import React from "react";
import { Skeleton } from "@mui/material";

export function ThreeSkeletons(props) {
  return (
    <>
      <div className="csc-default csc-frame csc-frame-default" {...props}>
        <Skeleton
          variant="circular"
          width={40}
          height={40}
          sx={{ margin: 2 }}
        />
        <Skeleton
          variant="rounded"
          width="50%"
          height={60}
          sx={{ margin: 2 }}
        />
      </div>
      <div className="csc-default csc-frame csc-frame-default" {...props}>
        <Skeleton
          variant="circular"
          width={40}
          height={40}
          sx={{ margin: 2 }}
        />
        <Skeleton
          variant="rounded"
          width="50%"
          height={60}
          sx={{ margin: 2 }}
        />
      </div>
      <div className="csc-default csc-frame csc-frame-default" {...props}>
        <Skeleton
          variant="circular"
          width={40}
          height={40}
          sx={{ margin: 2 }}
        />
        <Skeleton
          variant="rounded"
          width="50%"
          height={60}
          sx={{ margin: 2 }}
        />
      </div>
    </>
  );
}

import { Masonry } from "@mui/lab";
import { Alert, Box, ThemeProvider } from "@mui/material";
import React, { useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
import useSWR from "swr";
import { Event } from "./components/event";
import { ThreeSkeletons } from "./components/skeletons";
import { fetcher, theme } from "./config";
import { IEvent } from "./types";

function Container({ organization }: { organization: string }) {
  const [share, setShare] = useState(false);
  const [tagFilter, setTagFilter] = useState<string | null>(null);

  const {
    data: entries,
    error,
    isLoading,
  } = useSWR<{ data: IEvent[]; status: "success" | "error"; message: string }>(
    `https://data.gdch.app/api/events/org/${organization}`,
    fetcher
  );

  useEffect(() => {
    setShare(navigator.share ? true : false);
    try {
      // @ts-expect-error
      setTagFilter(tag);
    } catch (e) {
      console.log("No tag filter");
    }
  }, []);

  function filterByTag(e: IEvent) {
    if (tagFilter) {
      return e.tags.find((t) => t.match(new RegExp(`${tagFilter}`, "i")));
    } else {
      return true;
    }
  }

  return (
    <>
      {isLoading && <ThreeSkeletons />}
      {(error || entries?.status === "error") && (
        <>
          <Alert severity="error">
            Fehler: {error.message} {entries.message}
          </Alert>
          <Alert severity="info" sx={{ marginTop: 1 }}>
            Kontaktieren Sie{" "}
            <a href="mailto:m.braeutigam@gdch.de">Maximilian Bräutigam</a>
          </Alert>
        </>
      )}
      {entries?.status === "success" && entries.data.length > 0 && (
        <Box sx={{ marginRight: -2 }}>
          <Masonry
            spacing={2}
            columns={{ xs: 1, sm: 2, md: 2, lg: 2, xl: 2 }}
            defaultHeight={1000}
            defaultColumns={2}
            style={{ overflowX: "hidden", minHeight: "1000px" }}
          >
            {entries.data.filter(filterByTag).map((event) => (
              <Event event={event} share={share} key={event.id} />
            ))}
          </Masonry>
        </Box>
      )}
    </>
  );
}

const rootNode = document.getElementById("react-root");
const root = createRoot(rootNode);
root.render(
  <ThemeProvider theme={theme}>
    <Container
      /* @ts-expect-error => organization is defined in Typo 3 */
      organization={organization}
    />
  </ThemeProvider>
);
